import { LocaleMessages } from 'vue-i18n'

const messages: LocaleMessages = {
  en: {
    hello: 'hello',
    user: 'user',
    users: 'users',
    nbUser: 'no user | {n} user | {n} users',
    login: 'login',
    usernameOrEmail: 'username or email',
    password: 'password',
    connection: 'connection',
    forgotMyPassword: 'forgot my password ?',
    home: 'home',
    myAccount: 'my account',
    logout: 'logout',
    add: 'add',
    newUser: 'new user',
    addExistingUser: 'add existing user',
    date: 'date',
    expand: 'expand',
    collapse: 'collapse',
    noData: 'data unavailaible',
    delete: 'delete',
    activated: 'activated',
    desactivated: 'desactivated',
    edit: 'edit',
    username: 'username',
    email: 'email',
    role: 'role',
    roles: {
      superadmin: 'superadmin',
      admin: 'admin',
      user: 'user',
    },
    chooseNewPassword: 'choose a new password',
    validate: 'validate',
    typeUsernameOrPassword: 'type your username or email',
    choosePasswordActivateAccount: 'choose a password to activate your account',
    activateAccount: 'activate this account',
    cancel: 'cancel',
    editMyData: 'Edit my personal data',
    editMyPassword: 'Edit my password',
    deleteMyAccount: 'Delete my account',
    oldPassword: 'old password',
    newPassword: 'new password',
    iAmSur: 'I am sur',
    deleteAccountPassphrase: 'delete this account',
    askIfSurDeleteOwnAccount:
      'are you really sur to delete your account ? If you really want to, type this sentence and validate :',
    editImage: 'edit image',
    addImage: 'add image',
    welcomeHome: 'Welcome on private space',
    goodbye: 'Goodbye ',
  },
}

export default messages
